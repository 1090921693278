import axios from "axios";

/**
 * 获取机台视图列表
 */
export const fetchDeviceViewList = params => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/deviceViewHomePage",
    method: "GET",
    params
  });
};

/**
 * 完成转单，批量转单也是这个接口，操作成功，状态会变成 生产中
 */
export const fetchCompleteTransferOrder = data => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/transferOrder",
    method: "POST",
    data
  });
};

/**
 * 指定完工，可以选多个，操作成功，状态会变成 指定完工
 */
export const fetchCompleteProduction = data => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/endMassProductionOfdevice",
    method: "POST",
    data
  });
};

/**
 * 处理“转单异常”
 */
export const fetchHandleException = data => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/dealWithRecord",
    method: "POST",
    data
  });
};

/**
 * 获取生产机台列表，机台弹窗中展示
 */
export const fetchDeviceList = paramObj => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/deviceList",
    method: "GET",
    params: { param: paramObj }
  });
};
