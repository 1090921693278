// 搜索类型的 Select 下拉框列表
const searchTypeList = [
  {
    value: 1,
    label: "坯布指示单号"
  },
  {
    value: 2,
    label: "合同号"
  },
  {
    value: 3,
    label: "客户名称"
  },
  {
    value: 4,
    label: "客户款号"
  }
];

// 机台下发状态列表 —— checkboxGroup 复选框组
const transferOrderStatusList = [
  {
    value: 1,
    label: "待下发"
  },
  {
    value: 3,
    label: "下发失败"
  },
 
];

// 是否排产的 Select 下拉框列表
const deviceStatusList = [
  {
    value: -1,
    label: "全部"
  },
  {
    value: 1,
    label: "空闲"
  },
  {
    value: 2,
    label: "在产"
  }
];

export { searchTypeList, transferOrderStatusList, deviceStatusList };
