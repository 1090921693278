<template>
  <div id="page-device-view">
    <!-- 顶部筛选 -->
    <div class="common-filter-block">
      <div class="common-filter-item">
        <Select v-model="listParam.searchType" style="width: 120px">
          <Option v-for="item in searchTypeList" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.searchText"
          @keyup.enter.native="getTableListData"
          style="width: 200px"
        />
      </div>
      <div class="common-filter-item">
        <span style="text-align: right; margin-right: 10px">机台号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.machineNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
            <div class="common-filter-item">
        <span style="text-align: right; margin-right: 10px">坯布货号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.greyClothGoodsNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <div class="filter-checkbox">
          <Checkbox
            :indeterminate="isIndeterminate"
            :value="isCheckAll"
            @click.prevent.native="handleCheckAll"
            >全选</Checkbox
          >
          <span style="padding: 0 8px; color: #dcdada">|</span>
          <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
            <Checkbox
              v-for="item in transferOrderStatusList"
              :label="item.value"
              :key="item.value"
              >{{ item.label }}</Checkbox
            >
          </CheckboxGroup>
        </div>
      </div>
    </div>
    <!-- 自定义的表格操作栏 -->
    <div class="common-action-block">
      <div class="common-action-item" @click="getTableListData">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <div class="common-action-item" @click="completeTransferOrder">
        <i class="iconfont iconicon-xiafa"></i>
        批量下发
      </div>
      <div class="common-action-item" @click="exportDeviceList">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
    </div>

    <!-- 表格 -->
    <Table
      ref="selection"
      :border="true"
      :loading="loading"
      :columns="tableColumns"
      :data="tableData"
      @on-selection-change="selectionChange"
    >
      <template slot-scope="{ row }" slot="action">
        <span v-if="row.status === 1" class="common-able-click" @click="clickTransferOrder(row)"
          >下发</span
        >
        <div v-else-if="row.status === 3" class="common-able-click">
          <span style="margin-right: 10px" @click="deleteDeviceViewId(row)">删除</span>
          <span style="margin-right: 10px" @click="handleException(row)">处理异常</span>
        </div>
      </template>
      <template slot-scope="{ row, index }" slot="machineNo">
        <span v-if="row.status === 3" class="common-able-click" @click="clickMachineNo(index)">{{
          row.machineNo
        }}</span>
        <span v-else>{{ row.machineNo }}</span>
      </template>
      <template slot-scope="{ row }" slot="statusName">
        {{ row.statusName }}
        <Poptip word-wrap width="160" trigger="hover" :transfer="true" padding="16px 24px">
          <i v-if="row.status === 3" style="color: #f94040" class="iconfont iconicon-tishi"></i>
          <span style="color: #f94040" slot="content"
            >所选机台正处于在产状态，请更换机台或修改下发日期</span
          >
        </Poptip>
      </template>
      <template slot-scope="{ row }" slot="greyClothSheetNo">
        <span class="common-able-click" @click="goToDetailPage(row)">{{
          row.greyClothSheetNo
        }}</span>
      </template>
      <template slot-scope="{ row, index }" slot="transferOrderDate">
        <span
          v-if="row.status === 3"
          class="common-able-click"
          @click="clickTransferOrderDate(row, index)"
          >{{ row.transferOrderDate }}</span
        >
        <span v-else>{{ row.transferOrderDate }}</span>
      </template>
    </Table>
    <!-- 分页 -->
    <Page
      class-name="common-page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />

    <!-- 重新选择机台弹窗 -->
    <Modal
      :width="860"
      title="选择机台"
      v-model="isShowselectMachineModal"
      @on-ok="submitUpdateMachineNo"
      @on-visible-change="machineModalVisibleChange"
    >
      <!-- ## 机台表格的筛选部分 -->
      <Row style="margin-bottom: 20px" :gutter="20" justify="space-between">
        <Col span="8">
          <Input
            style="min-width: 200px"
            placeholder="通过机台号搜索"
            v-model.trim="machineListParam.machineNo"
            @input="getMachineTableListData"
          />
        </Col>
        <Col span="8">
          <Input
            placeholder="通过货号搜索"
            v-model.trim="machineListParam.greyClothItemNo"
            @input="getMachineTableListData"
          />
        </Col>
        <Col span="6"
          ><div style="display: flex; align-items: center; white-space: nowrap">
            <span style="padding-right: 10px">状态</span>
            <Select v-model="machineListParam.status" @on-change="getMachineTableListData">
              <Option v-for="item in deviceStatusList" :value="item.value" :key="item.value">{{
                item.label
              }}</Option>
            </Select>
          </div>
        </Col>
      </Row>

      <Table
        max-height="480"
        ref="machineTableRef"
        highlight-row
        :loading="machineLoading"
        :columns="machineTableColumns"
        :data="machineTableData"
        @on-current-change="handleCurrentChange"
      >
      </Table>
    </Modal>

    <!-- 修改日期的弹窗 -->
    <Modal
      class="select-machine-modal"
      title="下发日期修改"
      v-model="isShowUpdateDateModal"
      @on-ok="submitUpdateDate"
    >
      <DatePicker
        style="width: 100%"
        type="datetime"
        :clearable="false"
        placeholder="请输入日期"
        :value="currentUpdateDate"
        @on-change="handleDateChange"
      />
    </Modal>
  </div>
</template>

<script>
import { searchTypeList, transferOrderStatusList, deviceStatusList } from "./constant";
import {
  fetchDeviceViewList,
  fetchCompleteTransferOrder,
  fetchHandleException,
  fetchDeviceList,
} from "./api";

export default {
  name: "DeviceView",
  data() {
    return {
      // 筛选栏 复选框组的部分
      isCheckAll: false,
      isIndeterminate: false,
      checkAllGroup: [], // 和 this.listParam.statusList 有关联

      currentRowIndex: -1, // 首页表格当前操作的行的index
      ztdvIdList: [], // 首页已选择的机台视图id列表，包括对应的状态
      ztdvZtppIdList: [], // 首页已选择的排产表id列表
      currentSelectedMachine: null, // 机台弹窗表格的当前选中行对象

      // 首页表格列表的请求参数
      listParam: {
        searchType: 1, // 1:坯布指示单号,2:合同号,3:客户名称,4:款式（客户款号）
        searchText: "",
        machineNo: "",
        statusList: "1,3", // 下发状态 "1,3"， 1待下发，3下发失败
        greyClothGoodsNo: "",
        pageNum: 1,
        pageSize: 10,
      },

      // 首页表格配置
      loading: false,
      tableColumns: [
        {
          type: "selection",
          width: 54,
          align: "center",
        },
        {
          title: "序号",
          type: "index",
          width: 66,
        },
        {
          title: "操作",
          slot: "action",
          width: 142,
        },
        {
          title: "状态",
          slot: "statusName",
          key: "statusName",
          width: 118,
        },
        {
          title: "机台号",
          slot: "machineNo",
          key: "machineNo",
          width: 80,
        },
        {
          title: "坯布指示单号",
          slot: "greyClothSheetNo",
          key: "greyClothSheetNo",
          width: 130,
        },
        {
          title: "计划下发日期",
          slot: "transferOrderDate",
          key: "transferOrderDate",
          width: 156,
        },
        {
          title: "合同号",
          key: "contractNo",
          width: 140,
        },
        {
          title: "客户款号",
          key: "clientMoneyNo",
          width: 140,
        },
        {
          title: "坯布货号",
          key: "greyClothGoodsNo",
          width: 110,
        },

        {
          title: "实际下发日期",
          key: "factTransferOrderDate",
          width: 160,
        },
        {
          title: "计划日期",
          key: "planDate",
          width: 120,
        },
        {
          title: "预计完工日期",
          key: "predictFinishDate",
          width: 130,
        },
        {
          title: "交货日期",
          key: "deliveryDate",
          width: 120,
        },
        {
          title: "客户名称",
          key: "clientName",
          minWidth: 140,
        },
        {
          title: "颜色",
          key: "color",
          minWidth: 180,
        },
        {
          title: "备注",
          key: "remark",
          minWidth: 200,
        },
        {
          title: "修改日志",
          key: "modifyLog",
          minWidth: 400,
        },
      ],

      tableData: [],

      listDataTotal: 0, // 分页器数据总条数

      // 机台弹窗(只能单选)的配置
      machineListParam: {
        status: -1, // 状态（-1 全部，1 空闲， 2 在产）
        machineNo: "",
        greyClothItemNo: "",
      },

      machineTableColumns: [
        {
          title: "机台号",
          key: "machineNo",
          minWidth: 120,
        },
        {
          title: "设备类型",
          key: "deviceType",
          minWidth: 120,
        },
        {
          title: "状态",
          key: "status",
          minWidth: 120,
        },
        {
          title: "坯布货号",
          key: "ztgcpoGreyClothItemNo",
          minWidth: 120,
        },
        {
          title: "客户款号",
          key: "ztgcpoiClientNum",
          minWidth: 120,
        },
        {
          title: "预计完工日期",
          key: "planEndDate",
          minWidth: 120,
        },
      ],

      machineTableData: [],
      machineLoading: false,
      isShowselectMachineModal: false, // 选择机台模态框是否显示

      isResolvedRow: [], // 记录已经修改过异常的表格数据行

      isShowUpdateDateModal: false,
      currentUpdateDate: "", // 修改时间弹窗的日期绑定值

      searchTypeList,
      transferOrderStatusList,
      deviceStatusList, // 选择机台弹窗——设备状态的 Select 下拉框列表
    };
  },
  created() {
    this.getTableListData();
  },
  watch: {
    checkAllGroup: {
      handler(newVal, oldVal) {
        this.getTableListData();
      },
      immediate: true,
    },
  },
  methods: {
    // 复选框组单向联动单个的全选复选框
    checkAllGroupChange(data) {
      if (data.length === this.transferOrderStatusList.length) {
        // 全选状态
        this.isIndeterminate = false;
        this.isCheckAll = true;
      } else if (data.length > 0) {
        // 部分选择状态
        this.isIndeterminate = true;
        this.isCheckAll = false;
      } else {
        // 全不选状态
        this.isIndeterminate = false;
        this.isCheckAll = false;
      }

      this.listParam.statusList = data.join(",");
    },

    // 全选复选框的单击事件
    handleCheckAll() {
      if (this.isIndeterminate) {
        this.isCheckAll = false;
      } else {
        this.isCheckAll = !this.isCheckAll;
      }
      this.isIndeterminate = false;

      // 全选复选框单向联动相应的复选框组
      if (this.isCheckAll) {
        this.checkAllGroup = this.transferOrderStatusList.map((item) => item.value);
        this.listParam.statusList = this.transferOrderStatusList
          .map((item) => item.value)
          .join(",");
      } else {
        this.checkAllGroup = [];
        this.listParam.statusList = "";
      }
    },

    getTableListData() {
      this.loading = true;
      fetchDeviceViewList(this.listParam)
        .then((res) => {
          if (res.data.success === 1) {
            const { list } = res.data.body;

            this.tableData = list.list;
            this.listDataTotal = list.total;
            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMachineTableListData() {
      this.machineLoading = true;
      fetchDeviceList(this.machineListParam)
        .then((res) => {
          if (res.data.success === 1) {
            const { deviceList } = res.data.body;
            this.machineTableData = deviceList;
          } else {
            this.$Message.error(res.data.msg);
          }
          this.machineLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },

    // 进行批量下发
    completeTransferOrder() {
      // 若下发成功，对应机台的状态变化 待下发->生产中

      if (this.ztdvIdList.length === 0) {
        this.$Message.warning("请先选择待下发的机台！");
        return;
      }

      if (!this.ztdvIdList.every((item) => item.status === 1)) {
        this.$Message.warning("只有待下发的机台才可以进行下发！");
        return;
      }

      const ztdvIds = this.ztdvIdList.map((item) => item.ztdvId);

      fetchCompleteTransferOrder({
        ztdvIdList: ztdvIds,
        ztdvZtppIdList: this.ztdvZtppIdList,
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.info(res.data.msg);
            this.getTableListData();
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 导出表格
    exportDeviceList() {
      const { pageNum, pageSize, ...data } = this.listParam;
      const dataStr = this.qs.stringify(data);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/order/ProductPlanController/deviceViewExport?${dataStr}`;
    },

    // 首页多选发生改变
    selectionChange(selection) {
      this.ztdvIdList = selection.map((item) => {
        return {
          ztdvId: item.ztdvId,
          status: item.status,
        };
      });

      const ztdvZtppIdArray = selection.map((item) => item.ztdvZtppId); // 没去重的数组
      this.ztdvZtppIdList = Array.from(new Set(ztdvZtppIdArray)); // 数组去重
    },

    // 机台弹窗的表格当前行发生改变，单选
    handleCurrentChange(currentRow, oldCurrentRow) {
      this.currentSelectedMachine = currentRow;
    },

    // 单个机台完成下发
    clickTransferOrder({ ztdvId, ztdvZtppId }) {
      const ztdvIdArray = [];
      const ztdvZtppIdArray = [];
      ztdvIdArray.push(ztdvId);
      ztdvZtppIdArray.push(ztdvZtppId);

      fetchCompleteTransferOrder({ ztdvIdList: ztdvIdArray, ztdvZtppIdList: ztdvZtppIdArray })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.info(res.data.msg);
            this.getTableListData();
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    clickMachineNo(index) {
      this.currentRowIndex = index; // 记录一下当前操作的行的index
      // 弹出选择单个机台的分页列表弹窗
      this.isShowselectMachineModal = true;
      this.getMachineTableListData();
    },

    clickTransferOrderDate(row, index) {
      this.currentRowIndex = index; // 记录一下当前操作的行的index
      this.isShowUpdateDateModal = true;
      this.currentUpdateDate = this.tableData[index].transferOrderDate + ":00";
    },

    machineModalVisibleChange(isVisible) {
      if (!isVisible) {
        this.currentSelectedMachine = null;
      }
    },

    submitUpdateMachineNo() {
      if (this.currentSelectedMachine !== null) {
        const newTabeData = [...this.tableData];
        newTabeData[this.currentRowIndex].machineNo = this.currentSelectedMachine.machineNo;
        this.tableData = newTabeData;

        // 更改了机台，说明用户已经自己处理过了异常，记录一下
        this.isResolvedRow.push(this.tableData[this.currentRowIndex].ztdvId);
        this.isResolvedRow = Array.from(new Set(this.isResolvedRow)); // 去重
      }
    },

    submitUpdateDate() {
      if (
        this.currentUpdateDate &&
        this.moment(this.currentUpdateDate) <= this.moment(new Date())
      ) {
        this.$Message.warning("下发时间填写不规范，只能填写当前时间之后的时间日期！");
        return;
      }

      const newTabeData = [...this.tableData];

      let newTransferOrderDate = this.moment(this.currentUpdateDate).format("YYYY-MM-DD HH:ss:mm");
      newTransferOrderDate = this.moment(newTransferOrderDate).format("YYYY-MM-DD HH:ss"); // 需要转换2遍，精确到分钟，去掉秒
      newTabeData[this.currentRowIndex].transferOrderDate = newTransferOrderDate;
      this.tableData = newTabeData;

      // 更改了冲突的时间，说明用户已经自己处理过了异常，记录一下
      this.isResolvedRow.push(newTabeData[this.currentRowIndex].ztdvId);
      this.isResolvedRow = Array.from(new Set(this.isResolvedRow)); // 去重
    },

    handleDateChange(dataVal) {
      this.currentUpdateDate = dataVal;
    },

    goToDetailPage({ greyClothSheetNo }) {
      this.$router.push({
        path: "/workOrderManage/clothSheetDetail",
        query: { ztgcpoiWeaveDetail: greyClothSheetNo },
      });
    },

    deleteDeviceViewId(row) {
      fetchHandleException({
        dealType: 1, // 处理类型（1 删除，2 更新保存）
        deviceView: {
          ztdvId: row.ztdvId, // 机台视图id
          ztdvZtppId: row.ztdvZtppId, // 排产表ID
          ztdvTransferOrderDate: row.transferOrderDate, // 计划下发时间
          ztdvMachineNo: row.machineNo, // 机台号
        },
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("删除操作成功！");
            this.getTableListData();
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleException(row) {
      let isResolved = false;

      for (let i = 0; i < this.isResolvedRow.length; i++) {
        if (this.isResolvedRow[i] === row.ztdvId) {
          isResolved = true;
        }
      }

      if (this.isResolvedRow.length === 0 || !isResolved) {
        this.$Modal.warning({
          title: "处理异常",
          content: "请先更换机台或修改下发日期。",
        });

        return;
      }

      const cb = () => {
        const rowItem = { ...row };
        fetchHandleException({
          dealType: 2, // 处理类型（1 删除，2 更新保存）
          deviceView: {
            ztdvId: rowItem.ztdvId,
            ztdvZtppId: rowItem.ztdvZtppId,
            ztdvTransferOrderDate: rowItem.transferOrderDate,
            ztdvMachineNo: rowItem.machineNo,
          },
        })
          .then((res) => {
            if (res.data.success === 1) {
              this.$Message.success("处理异常操作成功！");
              this.getTableListData();
            } else {
              this.$Message.error(res.data.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };

      this.$Modal.confirm({
        title: "处理异常",
        content: "是否保存当前的更改？",
        onOk: () => cb(),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";

.filter-checkbox {
  display: flex;
  width: max-content;
}
</style>
<style lang="scss">
#page-device-view {
}
</style>
